import CryptoJS from 'crypto-js';

const serverUrl = 'https://api.eyes4384.com';
const apiUrl = 'https://api3.tapwallet.io';

export const mintFail = async () => {
  
  const url = `${serverUrl}/mint/fail`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const mintSuccessCheck = async (id) => {
  
  const url = `${serverUrl}/mint/success/check?id=${id}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}


export const mintCheck = async (number) => {
  
  const url = `${serverUrl}/mint/check?number=${number}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const mintCount = async (address) => {
  
  const url = `${serverUrl}/mint/count?address=${address}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const mintTotal = async (address) => {
  
  const url = `${serverUrl}/mint/totalCount`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const mintRecommend = async (count, address, privateKey, feerate) => {
  
  const url = `${serverUrl}/mint/recommend?count=${count}&address=${address}&privateKey=${privateKey}&feerate=${feerate}`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const mintCancel = async (ids) => {
  
  const url = `${serverUrl}/mint/cancel`;
  const data = {
    ids : ids
  }

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const mintRegister = async (data) => {
  
  const url = `${serverUrl}/mint/register`;

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const getAdminData = async () => {
  
  const url = `${serverUrl}/admin/status`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const saveAdminData = async (data) => {
  
  const url = `${serverUrl}/admin/_status`;

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}

export const registerPayment = async (key, address, inscriptionAddress = "") => {
  
  const url = `${serverUrl}/payment/register`;
  const data = {
    key: key,
    address: address,
    inscription_address: inscriptionAddress,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const registerToken = async (address, amount) => {
  
  const url = `${serverUrl}/token/register`;
  const data = {
    address: address,
    amount: amount,
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
}

export const tokenLatest = async () => {
  
  const url = `${serverUrl}/token/latest`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getMintStatus = async () => {
  
  const url = `${serverUrl}/mint/status`;

  let response  = await fetch(url, {
    method: 'GET', 
    headers: {
      'Content-Type': 'application/json',
    }
  })

  return await response.json();
}

export const getPaymentUtxos = async (paymentAddress, inscriptionAddress, inscriptionAmount, fundingAddress, fundingAmount, paymentPubkey, feeRate, amount, provider, realAmount) => {
  const data = {
    paymentAddress: paymentAddress,
    inscriptionAddress: inscriptionAddress,
    inscriptionAmount: inscriptionAmount,
    fundingAddress: fundingAddress,
    fundingAmount: fundingAmount,
    paymentPubkey: paymentPubkey,
    feeRate: feeRate,
    amount: parseInt(amount),
    provider: provider,
    realAmount: realAmount
  };

  try {
    const url = `${apiUrl}/getPaymentUtxos`;
  
    let response  = await fetch(url, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  
    return await response.json(); 
  }
  catch (e) {
    const url = `${serverUrl}/getPaymentUtxos`;
  
    let response  = await fetch(url, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  
    return await response.json();  
    
  }
}

export const getPaymentTx = async (psbtBase64) => {
  
  const url = `${serverUrl}/getPaymentTx`;
  const data = {
    psbtBase64: psbtBase64
  };

  let response  = await fetch(url, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })

  return await response.json();
}


export const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

